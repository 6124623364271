/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-14 11:33:34
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-10 18:10:13
 * @FilePath: /mediatom-web/src/viewsForManage/Budget/BudgetSource/searchMap.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const searchMap = {
  plat: {
    name: '广告平台',
    checked: true,
    prop: 'platIdList',
    default: [],
    disabled: true
  },
  account: {
    name: '所属账户',
    checked: true,
    prop: 'platAccountIdList',
    default: [],
    disabled: true
  },
  area: {
    name: '区域',
    checked: true,
    prop: 'area',
    default: '',
    disabled: true
  },
  positionName: {
    name: '广告样式',
    checked: false,
    prop: 'platPositionNameList',
    default: []
  },
  sts: {
    name: '状态',
    checked: false,
    prop: 'sts',
    default: ''
  },
  supportOs: {
    name: '系统类型',
    checked: false,
    prop: 'supportOsList',
    default: []
  },
  position: {
    name: '流量类型',
    checked: false,
    prop: 'supportPositionList',
    default: []
  },
  frequency: {
    name: '频次控制',
    checked: false,
    prop: 'frequency',
    default: undefined
  }
}
